import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { ProductDetail } from "../utils/product";

class ShopProduct extends React.Component {
  render() {
    const { data } = this.props;
    const product = data.allShopifyProduct.nodes[0];

    return (
      <Layout>
        <section className="container mt-3">
          <div className="columns">
            <div className="column is-12">
              <ProductDetail product={product} />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export const shopProductQuery = graphql`
  query shopProductQuery($ID: String!) {
    allShopifyProduct(filter: { id: { eq: $ID } }) {
      nodes {
        id
        handle
        title
        descriptionHtml
        shopifyId
        variants {
          shopifyId
          price
          title
        }
        media {
          preview {
            image {
              gatsbyImageData(layout: CONSTRAINED, width: 512)
            }
          }
        }
        totalInventory
      }
    }
  }
`;

export default ShopProduct;
